export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path.endsWith('/compagnie') && to.path !== '/compagnie') {
    const slug = to.path.slice(0, to.path.lastIndexOf('/compagnie'))
    return navigateTo(`/compagnie${slug}`)
  }

  if (to.path.endsWith('/navire') && to.path !== '/navire') {
    const slug = to.path.slice(0, to.path.lastIndexOf('/navire'))
    return navigateTo(`/navire${slug}`)
  }

  if (to.path.endsWith('/destination') && to.path !== '/destination') {
    const slug = to.path.slice(0, to.path.lastIndexOf('/destination'))
    return navigateTo(`/destination${slug}`)
  }

  if (to.path.startsWith('/faq/') && to.path !== '/faq' && to.path !== '/faq/') {
    return navigateTo(`/faq`)
  }

  if (to.path.startsWith('/lp/') && to.path !== '/lp' && to.path !== '/lp/') {
    const parts = to.path.split('/');
    return navigateTo(`/${parts[parts.length - 1]}`);
  }

  if (to.path.startsWith('/src/Frontend/Files/MediaLibrary/')) {
    const slug = to.path.split('/src/Frontend/Files/MediaLibrary/')[1]
    return navigateTo(`${process.env.STRAPI_BASE_URL}/uploads/legacy/${slug}`, { external: true })
  }

  const pageNumberRegex = /\/page-\d+$/;
  if (pageNumberRegex.test(to.path)) {
    return navigateTo(to.path.replace(/\/page-\d+$/, ''))
  }
})
