<script setup lang="ts">
import type { PropType } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

const props = defineProps({
  size: {
    type: String as PropType<'small' | 'medium' | 'large'>,
    default: 'medium',
  },
  colorStyle: {
    type: String as PropType<'gradiant' | 'flat' | 'outline' | 'link' | 'empty'>,
    default: 'gradiant',
  },
  variant: {
    type: String as PropType<'light' | 'dark'>,
    default: 'light',
  },
  type: {
    type: String as PropType<'submit' | 'button'>,
    default: 'button',
  },
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    default: undefined,
  },
})

const buttonClass = computed(() => {
  return [
    'button',
    props.colorStyle,
    props.variant,
    props.size,
  ]
})
</script>

<template>
  <NuxtLinkLocale
    v-if="to"
    :class="buttonClass"
    :type="type"
    :to="to"
  >
    <slot />
  </NuxtLinkLocale>
  <button
    v-else
    :class="buttonClass"
    :type="type"
  >
    <slot />
  </button>
</template>

<style lang="scss">
.button {
  color: inherit;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  gap: 0.5em;
  font-family: var(--font-primary);
  cursor: pointer;
  background: none;
  border: none;
  padding: 0.8em 1.6em;
  text-decoration: none;

  &[disabled] {
    opacity: .5;
  }

  &.small {
    font-size: 1rem;
    padding: 0.5em;
  }

  &.medium {
    font-size: 1.125rem;
  }

  &.large {
    font-size: 1.25rem;
  }

  &.light {
    --button-flat--color:  var(--color-navy-30);
    --button-flat--shadow: none;
    --button-flat--color-text: var(--color-navy-450);
    --button-flat--color-hover: var(--color-navy-450);

    --button-outline--color: var(--color-navy-450);
    --button-outline--color-hover: var(--color-navy-600);

    --button-gradiant--color-1: #656EB4;
    --button-gradiant--color-2: var(--color-navy-450);
    --button-gradiant--color-hover: var(--color-navy-600);
    --button-gradiant--color-text:  white;

    --button-link--color-text:  inherit;
  }

  &.dark {
    --button-flat--color: white;
    --button-flat--shadow: 0 0 0 2px inset white;
    --button-flat--color-text:  var(--color-navy-600);
    --button-flat--color-hover: transparent;

    --button-outline--color: white;
    --button-outline--color-hover: white;

    --button-gradiant--color-1: white;
    --button-gradiant--color-2: white;
    --button-gradiant--color-hover: var(--color-navy-30);
    --button-gradiant--color-text:  var(--color-navy-600);

    --button-link--color-text:  white;
  }

  &.gradiant {
    background: linear-gradient(180deg, var(--button-gradiant--color-1) 0%, var(--button-gradiant--color-2) 100%);
    color: var(--button-gradiant--color-text);

    &:hover {
      background: var(--button-gradiant--color-hover);
      color: (var(--color-white));
    }
  }

  &.outline {
    box-shadow: 0 0 0 1px inset var(--button-outline--color);
    color: var(--button-outline--color);

    &:hover {
      box-shadow: 0 0 0 2px inset var(--button-outline--color-hover);
      color: var(--button-outline--color-hover)
    }
  }

  &.flat {
    background: var(--button-flat--color);
    color: var(--button-flat--color-text);

    &:hover {
      box-shadow: var(--button-flat--shadow);
      background: var(--button-flat--color-hover);
      color: white;
    }
  }

  &.link {
    text-decoration: underline;
    color: var(--button-link--color-text)
  }

  &.empty {
    background: none;
    color: unset;
  }

  img {
    width: 24px;
    height: 24px;
  }
}
</style>
